import { render, staticRenderFns } from "./SurgerySubCategories.vue?vue&type=template&id=39d46584&scoped=true&"
import script from "./SurgerySubCategories.vue?vue&type=script&lang=js&"
export * from "./SurgerySubCategories.vue?vue&type=script&lang=js&"
import style0 from "./SurgerySubCategories.vue?vue&type=style&index=0&id=39d46584&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d46584",
  null
  
)

export default component.exports